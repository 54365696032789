import Skeleton from "react-loading-skeleton";

export default function FakeTextSkeleton({linesNumber, lineHeight = '17', gap = '2'}: {linesNumber: number, lineHeight: string,  gap: string}) {
    const getRandomWidth = () => `${80 + Math.round(Math.random() * 20)}%`;

    return (
        <div className={`flex flex-col gap-${gap}`}>
            {Array.from({ length: linesNumber }).map((_, index) => (
                <Skeleton
                    key={index}
                    style={{
                        width: getRandomWidth(),
                        height: `${lineHeight}px`,
                    }}
                />
            ))}
        </div>
    );
}
