import Image from 'next/image'

interface PageAuthorProps {
    authorName: string;
    authorPageLink: string;
    profilePicSrc: string;
    picSize?: number;
    textColor?: string;
    profilePicAlt?: string;
    publishDate?: string;
    modifiedDate?: string;
    redacted?: boolean;
}

export default function PageAuthor({
    authorName,
    authorPageLink,
    profilePicAlt,
    profilePicSrc,
    publishDate,
    modifiedDate,
    redacted,
    picSize = 30,
    textColor = 'white',
}: PageAuthorProps) {
    const date = modifiedDate ?? publishDate;
    const dateOptions = {year: 'numeric', month: 'long', day: 'numeric'};
    const dateObj = new Date(date ?? '');
    // @ts-ignore
    const formattedDate = dateObj.toLocaleDateString('fr-FR', dateOptions);

    const authorSchema = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "author": {
            "@type": "Person",
            "name": authorName,
            "jobTitle": "Responsable pôle expertise assurance emprunteur et mutuelle santé",
            "url": authorPageLink,
        },
    }

    return (
        <div className="flex justify-center items-center gap-2 h-12">
            <a href={authorPageLink} id="author" rel="author" aria-label="Photo auteur">
                <Image
                    className="rounded-full profile-pic mr-3"
                    src={profilePicSrc}
                    height={picSize}
                    width={picSize}
                    alt={profilePicAlt ?? ''}
                    sizes={`${picSize}px`}
                />
            </a>

            <span className={`text-${textColor} text-xs md:text-sm`}>
                <span className="text-nowrap">
                    {publishDate && !modifiedDate &&
                        `Publié le ${formattedDate} `
                    }
                    {modifiedDate &&
                        `Mis à jour le ${formattedDate} `
                    }
                    {redacted &&
                        `Rédigé `
                    }
                    par&nbsp;
                </span>
                <a className="underline" href={authorPageLink}>
                    {authorName}
                </a>
            </span>

            <script
                id="relatedLink"
                type="application/ld+json"
                dangerouslySetInnerHTML={{__html: JSON.stringify(authorSchema) ?? ''}}
            />
        </div>
    )
}
